<template>
  <div class="userinfo" @click="offHospital()">
    <el-form
      label-position="center"
      hide-required-asterisk
      :rules="rules"
      label-width="80px"
      :model="UserInfoForm"
      ref="UserInfoForm"
    >
      <el-form-item label="用户姓名:" prop="username">
        <el-input
          v-model="UserInfoForm.username"
          placeholder="请填写用户姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号:" prop="zjhm">
        <el-input
          v-model="UserInfoForm.zjhm"
          placeholder="请填写身份证号"
        ></el-input>
      </el-form-item>
      <el-form-item label="所在省份:" prop="province">
        <el-select
          v-model="UserInfoForm.province"
          placeholder="请选择所在省份"
          @change="provinceChange()"
        >
          <el-option
            v-for="item in mapList.province"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在城市:" prop="city">
        <el-select
          v-model="UserInfoForm.city"
          placeholder="请选择所在城市"
          @change="cityChange()"
        >
          <el-option
            v-for="item in mapList.city"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在区县:" prop="district">
        <el-select v-model="UserInfoForm.district" placeholder="请选择所在区域">
          <el-option
            v-for="item in mapList.area"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在医院:" prop="hospital" class="hospitalList">
        <!-- <el-input
          v-model="UserInfoForm.hospital"
          class="inputWidth"
          @input="hospitalChange2()"
          placeholder="请手动输入您的医院名称"
          v-if="switchValue"
        ></el-input> -->
        <el-input
          v-model="UserInfoForm.hospital"
          class="inputWidth"
          @input="hospitalChange()"
          placeholder="请输入您的医院名称"
        ></el-input>
        <!-- <el-tooltip
          content="切换至手动录入医院"
          placement="top"
          class="myswitch"
        >
          <el-switch
            v-model="switchValue"
            active-color="rgb(91 45 155)"
            inactive-color="rgb(120 115 115)"
            @change="UserInfoForm.hospital = ''"
          >
          </el-switch>
        </el-tooltip> -->
        <div
          class="hospitalList"
          v-if="hospitalListIsShow"
          v-loadMore="hospitalDel"
        >
          <ul class="hospitalListMore">
            <li
              v-for="item in mapList.hospital"
              :key="item.id"
              @click.stop="choiceHospital(item)"
            >
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
      </el-form-item>
      <el-form-item label="医院等级:" prop="h_level">
        <el-select
          v-model="UserInfoForm.h_level"
          placeholder="请选择所属医院等级"
        >
          <el-option
            v-for="item in hoslevelList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在科室:" prop="department_id">
        <el-select
          v-model="UserInfoForm.department_id"
          placeholder="请选择科室"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职称:" prop="zhicheng_id">
        <el-select v-model="UserInfoForm.zhicheng_id" placeholder="请选择职称">
          <el-option
            v-for="item in worksOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item id="mylogin">
        <el-button
          id="loginbtn"
          type="primary"
          @click="submitForm('UserInfoForm')"
          >保存修改</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getAreas, searchHospital } from "../API/city";
import { updateUserInfo, getUserInfo } from "../API/user";
import { getToken, hasToken, setToken } from "../../assets/js/storage";
export default {
  data() {
    return {
      switchValue: false,
      hospitalNum: 0,
      // 请求医院页码
      pageSize: {
        page: 1,
        limit: 10,
      },
      count: 0,
      // 医院等级
      hoslevelList: [
        "一级甲等",
        "一级丙等",
        "一级乙等",
        "二级甲等",
        "二级乙等",
        "二级丙等",
        "三级特等",
        "三级甲等",
        "三级丙等",
        "三级乙等",
        "其他",
      ],
      // 职称列表
      worksOptions: [],
      // 科室列表
      departmentOptions: [],
      // 地图列表
      mapList: {
        province: [],
        city: [],
        area: [],
        hospital: [],
      },
      hospitalListIsShow: false,
      // 表单列表
      UserInfoForm: {
        city: "",
        department_id: "",
        zjhm: "",
        district: "",
        email: "",
        hospital: "",
        hospital_id: "",
        h_level: "",
        mobile: "",
        province: "",
        sex: "",
        union_id: "",
        user_id: "",
        username: "",
        zhicheng: "",
        zhicheng_id: "",
      },

      // 表单校验规则
      rules: {
        username: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        zjhm: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            pattern:
              /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
            message: "请输入正确的身份证号 , 如果末尾是X的请输入大写字母X",
            trigger: "blur",
          },
        ],
        province: [
          { required: true, message: "请选择所在省份", trigger: "blur" },
        ],
        city: [{ required: true, message: "请选择所在市区", trigger: "blur" }],
        district: [
          { required: true, message: "请选择所在区县", trigger: "blur" },
        ],
        hospital: [
          { required: true, message: "请选择所在医院", trigger: "blur" },
        ],
        department_id: [
          { required: true, message: "请选择所在科室", trigger: "blur" },
        ],
        h_level: [
          { required: true, message: "请选择所属医院等级", trigger: "blur" },
        ],
        zhicheng_id: [
          { required: true, message: "请选择职称", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getProvince(0);
    this.getUserInfoHandle(getToken().user_id);
  },
  methods: {
    // 搜索建议表隐藏
    hospitalShowChange() {
      this.hospitalListIsShow = false;
    },
    // 输入框失去焦点
    blurChange() {
      if (this.mapList.hospital.length < 1) {
        this.hospitalListIsShow = false;
      }
    },
    // 医院列表触底
    async hospitalDel() {
      if (this.mapList.hospital.length < this.count) {
        this.pageSize.page++;
        // 获取医院信息
        const { data: res } = await searchHospital({
          hospital_name: this.UserInfoForm.hospital,
          ...this.pageSize,
        });
        if (res.code == "0000") {
          this.mapList.hospital.push(...res.data.list);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      } else {
        return;
      }
    },
    // 输入医院
    hospitalChange() {
      this.hospitalListIsShow = true;
      this.gethospital({
        ...this.pageSize,
        hospital_name: this.UserInfoForm.hospital,
      });
    },
    hospitalChange2() {
      this.UserInfoForm.hospital_id = 0;
    },
    // 选择医院
    choiceHospital(item) {
      this.UserInfoForm.hospital = item.name;
      this.UserInfoForm.hospital_id = item.id;
      this.hospitalListIsShow = false;
    },
    // 关闭医院列表
    offHospital() {
      this.hospitalListIsShow = false;
    },
    // 获取医院列表
    async gethospital(data) {
      const { data: res } = await searchHospital(data);
      this.mapList.hospital = res.data.list;
      this.count = res.data.count;
      if (this.count === 0) {
        this.hospitalListIsShow = false;
        this.UserInfoForm.hospital_id = 0;
        // this.$message('数据库未检索到您所在的医院，请手动输入')
      }
    },
    // 获取个人信息
    async getUserInfoHandle(id) {
      const { data: res } = await getUserInfo({ user_id: id });
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      this.worksOptions = res.data.zhicheng;
      this.departmentOptions = res.data.department;
      this.UserInfoForm = res.data.user;
      for (const key in this.UserInfoForm) {
        if (this.UserInfoForm[key] == 0) {
          this.UserInfoForm[key] = "";
        }
      }
      delete this.UserInfoForm.sex;
      delete this.UserInfoForm.email;
    },
    // 选择省/市信息
    provinceChange() {
      this.UserInfoForm.city = "";
      this.UserInfoForm.district = "";
      this.mapList.city = [];
      this.mapList.area = [];
    },
    cityChange() {
      this.UserInfoForm.district = "";
      this.mapList.area = [];
    },
    // 获取省份信息
    async getProvince(id) {
      const { data: res } = await getAreas(id);
      if (res.code == "0000") {
        this.mapList.province = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.count === 0) {
            this.UserInfoForm.hospital_id = 0;
          }
          if (this.hospitalListIsShow) {
            return this.$message("请选择您的医院");
          }
          const { data: res } = await updateUserInfo(this.UserInfoForm);
          if (res.code !== "0000") {
            return this.$message(res.msg);
          }
          this.$message.success("保存成功");
          this.getUserInfoHandle(getToken().user_id);
        } else {
          return false;
        }
      });
    },
  },
  // 监听器
  watch: {
    "UserInfoForm.province": async function (newV) {
      // 获取市信息
      const { data: res } = await getAreas(newV);
      if (res.code == "0000") {
        this.mapList.city = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    "UserInfoForm.city": async function (newV) {
      // 获取市信息
      const { data: res } = await getAreas(newV);
      if (res.code == "0000") {
        this.mapList.area = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    "UserInfoForm.zhicheng_id": function (newV) {
      // 获取市信息
      const newArr = this.worksOptions.filter((item) => {
        return item.id == newV;
      });
      this.UserInfoForm.zhicheng = newArr[0].name;
      // console.log(newArr[0].name);
    },
    "UserInfoForm.hospital": function (newV) {
      if (newV == "") {
        this.hospitalListIsShow = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.userinfo {
  .myswitch {
    position: absolute;
    right: 370px;
    top: 10px;
    @media screen and (max-width: 540px) {
      right: 10px;
    }
  }
  .hospitalList {
    // position: relative;
    .hospitalList {
      position: absolute;
      left: 0;
      top: 45px;
      z-index: 999;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      border-radius: 4px;
      border: 1px solid #e4e7ed;
      width: 400px;
      padding: 10px 0;
      background-color: #fff;
      ul {
        height: 280px;
        overflow-y: scroll;
        li {
          box-sizing: border-box;
          cursor: pointer;
          p {
            padding: 0 15px;
          }
        }
        li:hover {
          background-color: #eee;
        }
      }
    }
  }

  .my-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .addr {
        color: #ddd;
      }
    }
  }
  padding: 60px 0 0 140px;
  @media screen and (max-width: 540px) {
    padding: 0;
  }
  .el-input {
    width: 400px;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
  .el-select {
    width: 400px;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
  #loginbtn {
    width: 150px;
    height: 44px;
    background: #293f8e;
    border-radius: 2px;
    font-size: 18px;
    color: #ffffff;
    border: 0;
    margin-top: 20px;
  }
}
</style>
